import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { PopupButton } from '@typeform/embed-react'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ header }) => {
  const scrollHandler = () => {
    if (!window) return false

    const button = document.querySelector('#btn-action')
    const header = document.querySelector(`.${styles.header}`)

    if (button && header) {
      if (header.getBoundingClientRect().bottom < 200) {
        button.classList.remove('hidden')
      } else {
        button.classList.add('hidden')
      }
    }
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
    scrollHandler()
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  const scrollToProtocols = () => {
    gsap.to(window, {
      duration: 1.5,
      scrollTo: document.getElementById('protocols'),
    })
  }

  const openLiveChat = () => {
    try {
      window.Intercom('showSpace', 'messages')
    } catch {}
  }

  return (
    <div className={styles.header}>
      <img className={styles.bg} src={sanityImageUrl(header.image)} alt={header.image?.caption} />
      <img className={styles.bgTablet} src={sanityImageUrl(header.imageTablet)} alt={header.imageTablet?.caption} />
      <div className={styles.wrap}>
        <div className={styles.fh}>
          <div className={styles.middle}>
            <div className={styles.chat} onClick={openLiveChat}>
              <div className={styles.chatImage}>
                <img src={sanityImageUrl(header.chatImage)} alt={header.chatImage?.caption} />
              </div>
              <div className={styles.chatText}>
                <p className={styles.chatTitle}>{header.chatTitle}</p>
                <p className={styles.chatLink}>{header.chatLink}</p>
              </div>
            </div>          
            <div className={styles.articles}>
              {header.press &&
                header.press.map(item => (
                  <div className={styles.article} key={item._key}>
                    {item.url ? (
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <img
                          className={styles.logo}
                          {...srcSetProps(sanityImageUrl(item.logo))}
                          alt={item.logo?.caption}
                        />
                      </a>
                    ) : (
                      <img
                        className={styles.logo}
                        {...srcSetProps(sanityImageUrl(item.logo))}
                        alt={item.logo?.caption}
                      />
                    )}
                  </div>
                ))}
            </div>          
            <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: header.title }} />
            <p className={styles.desc}>{header.smallTitle}</p>

            <div className={styles.controls}>
              <PopupButton
                id={header.blueButtonUrl.replace('https://quiz/', '')}
                as="a"
                transitiveSearchParams={true}
                className={styles.controlsButton}
              >
                {header.blueButtonText}
              </PopupButton>
              <a onClick={scrollToProtocols} className={styles.controlsLink}>
                {header.protocolsButtonText}
              </a>
            </div>
          </div>
          <div className={styles.bgMobile}>
            <img
              className={styles.bgMobileSrc}
              src={sanityImageUrl(header.imageMobile)}
              alt={header.imageMobile?.caption}
            />
          </div>
          <div className={styles.bottom}>
            {header.links &&
              header.links.map(link => (
                <Link
                  to={link.url}
                  className={styles.bottomLink}
                  key={link.id}
                  dangerouslySetInnerHTML={{ __html: link.title }}
                />
              ))}
          </div>
          <div className={styles.mobile}>
            <div className={styles.articles}>
              {header.press &&
                header.press.map(item => (
                  <div className={styles.article} key={item._key}>
                    {item.url ? (
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <img
                          className={styles.logo}
                          {...srcSetProps(sanityImageUrl(item.logo))}
                          alt={item.logo?.caption}
                        />
                      </a>
                    ) : (
                      <img
                        className={styles.logo}
                        {...srcSetProps(sanityImageUrl(item.logo))}
                        alt={item.logo?.caption}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
